<template>
  <div class="users-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">用户壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">用户列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.$route.query.itemName}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/index/' + this.$route.params.mId }">用户内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-bar">
      <div class="left">
        <el-form :inline="true" :model="query" class="search-form-inline">
          <el-form-item>
            <el-input v-model="query.nick" placeholder="请输入用户昵称" clearable @clear="getDataList"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getDataList">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="right">
      </div>
    </div>
    <div class="page-content" v-if="dataList.length > 0">
      <el-table :data="dataList" border style="width: 100%" max-height="500">
        <el-table-column prop="id" label="#" width="80"></el-table-column>
        <el-table-column prop="m_id" label="混合ID" width="120"></el-table-column>
        <el-table-column prop="username" label="用户名"></el-table-column>
        <el-table-column prop="nick" label="昵称"></el-table-column>
        <el-table-column prop="nick" label="头像">
          <template slot-scope="scope">
            <img :src="scope.row.avatar" style="width: 50px; height: 50px;"/>
          </template>
        </el-table-column>
        <el-table-column prop="nick" label="来源平台">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.platform === 1">公众号</el-tag>
            <el-tag type="success" v-if="scope.row.platform === 2">小程序</el-tag>
            <el-tag type="info" v-if="scope.row.platform === 3">网站</el-tag>
            <el-tag type="warning" v-if="scope.row.platform === 4">未知</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="profile_status" label="是否已完善资料">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.profile_status === 1">已完善</el-tag>
            <el-tag v-if="scope.row.profile_status === 2">待审核</el-tag>
            <el-tag type="info" v-if="scope.row.profile_status === 3">禁止访问受限内容</el-tag>
            <el-tag type="warning" v-if="scope.row.profile_status === 4">未完善</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="last_time" label="最后登录时间">
          <template slot-scope="scope">
            {{datafromatfull(scope.row.last_time)}}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="300px">
          <template slot-scope="scope">
            <el-button type="" size="mini" class="table-btn" @click="viewItem(scope.row.m_id)">查看账号详细</el-button>
            <el-button type="success" class="table-btn" size="mini" @click="authStatus(1, scope.row.m_id)" v-if="scope.row.profile_status === 3 || scope.row.profile_status === 2">通过受限审核</el-button>
            <el-button type="danger" class="table-btn" size="mini" @click="authStatus(3, scope.row.m_id)" v-if="scope.row.profile_status === 2 || scope.row.profile_status === 1">拒绝通过</el-button>
            <el-button type="info" class="table-btn" size="mini" @click="authStatus(2, scope.row.m_id)" v-if="scope.row.profile_status === 3 || scope.row.profile_status === 1">转为待审核状态</el-button>
            <el-button type="danger" class="table-btn" size="mini" @click="clearProfile(scope.row.m_id)" v-if="scope.row.profile_status !== 4">清空用户资料</el-button>
            <el-button type="danger" class="table-btn" size="mini" @click="delItem(scope.row.m_id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.currentPage" :page-sizes="[20, 50, 100]" :page-size="query.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="query.total"></el-pagination>
      </div>
    </div>
    <div class="no-list" v-else>
      <el-empty :image-size="200"></el-empty>
    </div>
    <!-- 查看用户详情弹窗 -->
    <el-dialog title="用户详情" :visible.sync="profileVisible" width="80%" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="profileClose">
      <div class="profile-panle">
        <div class="panle-title">
          ID: {{userInfo.m_id}}
          <span style="margin-left: 16px;">创建时间：{{datafromatfull(userInfo.creat_time)}}</span>
        </div>
        <div class="panel-content">
          <el-row :gutter="20">
            <el-col :span="6">
              <div class="item-title">用户名:</div>
              <div class="item-content" v-if="userInfo.username">
                {{userInfo.username}}
              </div>
              <div class="item-content" v-else>
                <el-tag type="danger" effect="dark">未设置</el-tag>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item-title">openid:</div>
              <div class="item-content" v-if="userInfo.openid">
                {{userInfo.openid}}
              </div>
              <div class="item-content" v-else>
                <el-tag type="danger" effect="dark">未设置</el-tag>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item-title">昵称:</div>
              <div class="item-content" v-if="userInfo.nick">
                {{userInfo.nick}}
              </div>
              <div class="item-content" v-else>
                <el-tag type="danger" effect="dark">未设置</el-tag>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item-title">头像:</div>
              <div class="item-content" v-if="userInfo.avatar">
                <img :src="userInfo.avatar" style="width: 100px;height: 100px"/>
              </div>
              <div class="item-content" v-else>
                <el-tag type="danger" effect="dark">未设置</el-tag>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item-title">手机号码:</div>
              <div class="item-content" v-if="userInfo.phone">
                {{userInfo.phone}}
              </div>
              <div class="item-content" v-else>
                <el-tag type="danger" effect="dark">未设置</el-tag>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item-title">邮箱:</div>
              <div class="item-content" v-if="userInfo.mail">
                {{userInfo.mail}}
              </div>
              <div class="item-content" v-else>
                <el-tag type="danger" effect="dark">未设置</el-tag>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item-title">来源平台:</div>
              <div class="item-content">
                <el-tag type="warning" effect="dark" v-if="userInfo.platform === 1">公众号</el-tag>
                <el-tag type="success" effect="dark" v-if="userInfo.platform === 2">小程序</el-tag>
                <el-tag type="" effect="dark" v-if="userInfo.platform === 3">网页</el-tag>
                <el-tag type="danger" effect="dark" v-if="userInfo.platform === 4">未知</el-tag>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item-title">真实姓名:</div>
              <div class="item-content" v-if="userInfo.real_name">
                {{userInfo.real_name}}
              </div>
              <div class="item-content" v-else>
                <el-tag type="danger" effect="dark">未设置</el-tag>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item-title">性别:</div>
              <div class="item-content">
                <el-tag type="warning" effect="dark" v-if="userInfo.sex === 1">男</el-tag>
                <el-tag type="success" effect="dark" v-if="userInfo.sex === 2">女</el-tag>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item-title">证件类型:</div>
              <div class="item-content">
                <el-tag type="warning" effect="dark" v-if="userInfo.cardtype === 1">身份证</el-tag>
                <el-tag type="success" effect="dark" v-if="userInfo.cardtype === 2">护照</el-tag>
                <el-tag type="" effect="dark" v-if="userInfo.cardtype === 3">军官证</el-tag>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item-title">证件号码:</div>
              <div class="item-content" v-if="userInfo.cardnum">
                {{userInfo.cardnum}}
              </div>
              <div class="item-content" v-else>
                <el-tag type="danger" effect="dark">未设置</el-tag>
              </div>
            </el-col>
<!--            <el-col :span="6">-->
<!--              <div class="item-title">半身照:</div>-->
<!--              <div class="item-content" v-if="userInfo.photo">-->
<!--                <img :src="userInfo.photo" style="width: 100px;height: 100px"/>-->
<!--              </div>-->
<!--              <div class="item-content" v-else>-->
<!--                <el-tag type="danger" effect="dark">未上传</el-tag>-->
<!--              </div>-->
<!--            </el-col>-->
            <el-col :span="6">
              <div class="item-title">单位名称:</div>
              <div class="item-content" v-if="userInfo.company">
                {{userInfo.company}}
              </div>
              <div class="item-content" v-else>
                <el-tag type="danger" effect="dark">未设置</el-tag>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item-title">开票类型:</div>
              <div class="item-content">
                <el-tag type="warning" effect="dark" v-if="userInfo.invoice_type === 1">普票</el-tag>
                <el-tag type="success" effect="dark" v-if="userInfo.invoice_type === 2">专票</el-tag>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item-title">开票税号:</div>
              <div class="item-content" v-if="userInfo.invoice_number">
                {{userInfo.invoice_number}}
              </div>
              <div class="item-content" v-else>
                <el-tag type="danger" effect="dark">未填写</el-tag>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item-title">开户行:</div>
              <div class="item-content" v-if="userInfo.deposit">
                {{userInfo.deposit}}
              </div>
              <div class="item-content" v-else>
                <el-tag type="danger" effect="dark">未填写</el-tag>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="item-title">注册地址:</div>
              <div class="item-content" v-if="userInfo.readdress">
                {{userInfo.readdress}}
              </div>
              <div class="item-content" v-else>
                <el-tag type="danger" effect="dark">未填写</el-tag>
              </div>
            </el-col>
<!--            <el-col :span="6">-->
<!--              <div class="item-title">所在科室:</div>-->
<!--              <div class="item-content" v-if="userInfo.offices">-->
<!--                {{userInfo.offices}}-->
<!--              </div>-->
<!--              <div class="item-content" v-else>-->
<!--                <el-tag type="danger" effect="dark">未设置</el-tag>-->
<!--              </div>-->
<!--            </el-col>-->
<!--            <el-col :span="6">-->
<!--              <div class="item-title">现任职务:</div>-->
<!--              <div class="item-content" v-if="userInfo.duties">-->
<!--                {{userInfo.duties}}-->
<!--              </div>-->
<!--              <div class="item-content" v-else>-->
<!--                <el-tag type="danger" effect="dark">未设置</el-tag>-->
<!--              </div>-->
<!--            </el-col>-->
<!--            <el-col :span="6">-->
<!--              <div class="item-title">营业执照:</div>-->
<!--              <div class="item-content" v-if="userInfo.license">-->
<!--                <img :src="userInfo.license" style="width: 100px;height: 100px"/>-->
<!--              </div>-->
<!--              <div class="item-content" v-else>-->
<!--                <el-tag type="danger" effect="dark">未上传</el-tag>-->
<!--              </div>-->
<!--            </el-col>-->
<!--            <el-col :span="6">-->
<!--              <div class="item-title">单位委托（授权）函:</div>-->
<!--              <div class="item-content" v-if="userInfo.entrust">-->
<!--                <img :src="userInfo.entrust" style="width: 100px;height: 100px"/>-->
<!--              </div>-->
<!--              <div class="item-content" v-else>-->
<!--                <el-tag type="danger" effect="dark">未上传</el-tag>-->
<!--              </div>-->
<!--            </el-col>-->
            <el-col :span="6">
              <div class="item-title">资料完善状态:</div>
              <div class="item-content">
                <el-tag type="success" effect="dark" v-if="userInfo.profile_status === 1">正常</el-tag>
                <el-tag type="warning" effect="dark" v-if="userInfo.profile_status === 2">待审核</el-tag>
                <el-tag type="danger" effect="dark" v-if="userInfo.profile_status === 3">禁止访问受限内容</el-tag>
                <el-tag type="info" effect="dark" v-if="userInfo.profile_status === 4">待完善资料</el-tag>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="profileVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      query: {
        nick: '',
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      dataList: [],
      userInfo: {
        m_id: '',
        username: '',
        openid: '',
        nick: '',
        avatar: '',
        platform: 1,
        real_name: '',
        sex: 1,
        cardtype: 1,
        cardnum: '',
        phone: '',
        mail: '',
        photo: '',
        company: '',
        offices: '',
        duties: '',
        license: '',
        entrust: '',
        profile_status: 1,
        last_time: 0,
        creat_time: 0,
        invoice_type: 1,
        invoice_number: '',
        deposit: '',
        readdress: ''
      },
      profileVisible: false
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    // 获取本会议的用户列表
    async getDataList () {
      const { data: res } = await this.$http.get('shell-meeting-users-list/' + this.$route.params.mId, { params: this.query })
      if (res.status === 200) {
        this.dataList = res.data.list.data
        this.query.total = Number(res.data.list.total)
        this.query.currentPage = Number(res.data.list.current_page)
        this.query.pageSize = Number(res.data.pageSize)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 添加用户
    addUser () {
      this.$router.push('/admin/apps/shell/add')
    },
    // 编辑用户
    editItem (mId) {
      this.$router.push('/admin/apps/shell/edit/' + mId)
    },
    // 删除用户
    async delItem (mId) {
      const { data: res } = await this.$http.delete('/shell-user-single-meeting/' + mId)
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getDataList()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 更改每页显示数量
    handleSizeChange (val) {
      this.query.pageSize = val
      this.getDataList()
    },
    // 更改页码
    handleCurrentChange (val) {
      this.query.currentPage = val
      this.getDataList()
    },
    datafromatfull (time) {
      return this.$moment.unix(time).format('YYYY-MM-DD HH:mm')
    },
    async viewItem (mid) {
      const { data: res } = await this.$http.get('shell-meeting-users-profile', { params: { m_id: mid } })
      if (res.status === 200) {
        this.userInfo = res.data
        this.profileVisible = true
      } else {
        this.$message.error(res.msg)
      }
    },
    profileClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => { done() })
        .catch(_ => {})
    },
    // 切换用户受限访问状态
    async authStatus (status, mid) {
      const { data: res } = await this.$http.post('shell-meeting-users-auth', { status: status, m_id: mid, mtitle: this.$route.query.itemName })
      if (res.status === 200) {
        await this.getDataList()
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 清空用户资料
    async clearProfile (mid) {
      const { data: res } = await this.$http.post('shell-meeting-clear-user', { m_id: mid })
      if (res.status === 200) {
        await this.getDataList()
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
.search-bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-form-inline .el-form-item{
  margin-bottom: 0;
}
.footer-pagination{
  padding: 10px;
  background-color: #FFFFFF;
}
.profile-panle{
  text-align: left;
}
.panle-title{
  padding: 10px 0;
  font-weight: bold;
  color: #303133;
}
.panel-content{
  padding: 10px;
  border: 1px solid #DCDFE6;
}
.panel-content .item-title{
  background-color: #F2F6FC;
  padding: 10px;
  font-weight: bold;
}
.panel-content .item-content{
  padding: 10px 0;
}
.table-btn{
  margin-bottom: 10px;
}
</style>
